import { DotPadSendModule } from "./DotPadSendModule";
import { DotpadResize } from "./DotpadResize";

class DotpadSDK {  // eslint-disable-line no-unused-vars
    constructor(dotPad) {
        //if (DotpadSDK_Instance) return DotpadSDK_Instance;
        this.VOXEL_ROW_NUM = 40;
        this.VOXEL_COL_NUM = 60;
        this.LINE_HEIGHT = 20;
        this.DOT_CELL_PIN = 8;
        this.dot_Matrix_All_300 = this.create2DArray(this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM);
        this.dtm_Data_300 = new Uint8Array(300);
        this.length = 10
        this.numCell = 30        
        this.stringwithPadding = "";
        this.indexPadding = 1;
        this.SubString = "";
        this.writeString_Wrap = "";
        this.writeString = "";
        this.res = false;
        this.dotPad = dotPad;
        this.dotPadSendModule = new DotPadSendModule(dotPad);
        this.lineCount = 0;

        if (dotPad) {
            this.dotPadSendModule.addDotPad320Line();
            dotPad.setDotPadSendModule(this.dotPadSendModule);
        }
    }

    create2DArray(rows, columns) {
        var arr = new Array(rows);
        for (var i = 0; i < rows; i++) {
            arr[i] = new Array(columns);
        }
        return arr;
    }

    toHexString(byteArray) {
        return Array.from(byteArray, function (byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('')
    }

    decimalToHex(d, padding) {
        var hex = Number(d).toString(16);
        padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

        while (hex.length < padding) {
            hex = "0" + hex;
        }

        return hex;
    }

    // Convert a hex string to a byte array
    hexToBytes(hex) {
        const bytes = [];

        try {
            for (let c = 0; c < hex.length; c += 2)
                bytes.push(parseInt(hex.substring(c, c + 2), 16));
        } catch (e) {
            // console.log(bytes);
            console.error(e);
        }

        return bytes;
    }

    /**
     * Convert a hex string to an ArrayBuffer.
     *
     * @param {string} hexString - hex representation of bytes
     * @return {ArrayBuffer} - The bytes in an ArrayBuffer.
     */
    hexStringToArrayBuffer(hexString) {
        // remove the leading 0x
        hexString = hexString.replace(/^0x/, '');

        // ensure even number of characters
        if (hexString.length % 2 != 0) {
            console.log('WARNING: expecting an even number of characters in the hexString');
        }

        // check for some non-hex characters
        var bad = hexString.match(/[G-Z\s]/i);
        if (bad) {
            console.log('WARNING: found non-hex characters', bad);
        }

        // split the string into pairs of octets
        var pairs = hexString.match(/[\dA-F]{2}/gi);

        // convert the octets to integers
        var integers = pairs.map(function (s) {
            return parseInt(s, 16);
        });

        var array = new Uint8Array(integers);
        // console.log(array);

        return array.buffer;
    }

    /**
     * Convert a 600-character Hex String to 2400 Boolean arrays.
     *
     * @param {string} dtmHexaString - 600 Hex String (1Hex=4bit)
     * @return {Array} - 2400 Boolean Array
     */
    hexToBooleanArray(dtmHexaString) {
      let _graphicData = dtmHexaString;
      let byteArr = this.hexToBytes(_graphicData); // 300 Bytes Array
      let booleanArr = [];

      // Since there are eight pins of 300 cells, it will be set as below
      for (let i = 0; i < byteArr.length; i++) {
        let start_index = parseInt(i / 30) * 60 * 4 + (i % 30) * 2;
        booleanArr[start_index] = byteArr[i] & (0x01 << 0) ? true : false; // 1st Pin
        booleanArr[start_index + 60] = byteArr[i] & (0x01 << 1) ? true : false; // 2nd Pin
        booleanArr[start_index + 120] = byteArr[i] & (0x01 << 2) ? true : false; // 3rd Pin
        booleanArr[start_index + 180] = byteArr[i] & (0x01 << 3) ? true : false; // 4th Pin
        booleanArr[start_index + 1] = byteArr[i] & (0x01 << 4) ? true : false; // 5th Pin
        booleanArr[start_index + 61] = byteArr[i] & (0x01 << 5) ? true : false; // 6th Pin
        booleanArr[start_index + 121] = byteArr[i] & (0x01 << 6) ? true : false; // 7th Pin
        booleanArr[start_index + 181] = byteArr[i] & (0x01 << 7) ? true : false; // 8th Pin
      }

      return booleanArr;
    }

    checksum(test_data) {
        var check = 0xA5;

        for (var i = 0; i < test_data.length; i++) {
            check = check ^ test_data[i]
            //console.log(i, test_data[i+4].toString(16), check.toString(16));
        }
        let res = ('0' + (check & 0xFF).toString(16)).slice(-2);
        return res;
    }

    /**
     Make a Data set using Application's Canvas Data(voxels: Array<Array<Bool>>).
     - warning: None.
     - parameter deviceType: DeviceType
     - parameter voxels: Array<Array<Bool>>
     - returns:
     */
    Load_mapFile(voxels) {
        let order = 0
        for (let i = 0; i < this.VOXEL_ROW_NUM; i++) {
            for (let j = 0; j < this.VOXEL_COL_NUM; j++) {
                if (voxels[order] == true) {
                    this.dot_Matrix_All_300[i][j] = 0x01
                } else {
                    this.dot_Matrix_All_300[i][j] = 0x00
                }
                order++;
            }
        }
        //console.log('this.dot_Matrix_All_300 : ' + this.dot_Matrix_All_300.length + ", " + this.dot_Matrix_All_300[0].length + " : " + this.dot_Matrix_All_300);
    }

    /**
     Make a One Cell Data.
     - warning: None.
     - parameter cell_ID: Int
     - returns: None
     */
    Sub_dot_Cell_Matrix(cell_ID) {
        let _dot_Cell = this.create2DArray(4, 2);

        //console.log("[Sub_dot_Cell_Matrix] cell_ID : " + cell_ID);
        //console.log("[Sub_dot_Cell_Matrix] _dot_Cell[3][0] : " + parseInt((cell_ID / 30) * 4 + 3) + ", " + parseInt((cell_ID % 30) * 2));

        _dot_Cell[0][0] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4][(cell_ID % 30) * 2];
        _dot_Cell[0][1] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4][(cell_ID % 30) * 2 + 1];
        _dot_Cell[1][0] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 1][(cell_ID % 30) * 2];
        _dot_Cell[1][1] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 1][(cell_ID % 30) * 2 + 1];

        _dot_Cell[2][0] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 2][(cell_ID % 30) * 2];
        _dot_Cell[2][1] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 2][(cell_ID % 30) * 2 + 1];
        _dot_Cell[3][0] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 3][(cell_ID % 30) * 2];
        _dot_Cell[3][1] = this.dot_Matrix_All_300[parseInt(cell_ID / 30) * 4 + 3][(cell_ID % 30) * 2 + 1];

        return _dot_Cell;
    }

    /**
     Transform One Cell Data to Byte Value.
     - warning: None.
     - parameter sub_Cell: [[UInt8]]
     - returns: [[UInt8]]
     */
    Get_dot_Cell_Byte(sub_Cell) {
        var cell_Byte = 0x0

        cell_Byte = ((sub_Cell[0][0]) + ((sub_Cell[0][1] << 4) & 0xff) + ((sub_Cell[1][0] << 1) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[1][1] << 5) & 0xff) + ((sub_Cell[2][0] << 2) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[2][1] << 6) & 0xff) + ((sub_Cell[3][0] << 3) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[3][1] << 7) & 0xff));

        //console.log('cell_Byte : ' + cell_Byte);

        return cell_Byte;
    }

    /**
     Make a DTM File Format without DTM PREFIX.
     - warning: None.
     - parameter deviceType: DeviceType
     - returns: None
     */
    Make_DTM_Data() {
        var _dot_Cell = this.create2DArray(4, 2);

        for (let Cell_ID = 0; Cell_ID < 300; Cell_ID++) {
            _dot_Cell = this.Sub_dot_Cell_Matrix(Cell_ID);
            this.dtm_Data_300[Cell_ID] = this.Get_dot_Cell_Byte(_dot_Cell);
        }

        this.writeString = this.toHexString(this.dtm_Data_300);
        //print("[DotPad_ProcessData] Make_DTM_Data : dtm_Data_300 : \(dtm_Data_300)")

        // 닷패드 미연결 상태일 경우 다음 로직 실행하지 않음
        if (!this.dotPad) return;

        // 768셀인 경우 닷패드 출력 데이타 생성 (COL-48개셀, ROW-16줄)
        if (this.dotPad.deviceInfo.deviceName.includes("768")) {
            const resize = new DotpadResize(this, this.dtm_Data_300, 48, 16);
            resize.setResizeCenter();
        }
    }

    /**
     Send Data of Application's Canvas Data to dotPad_Communication.
     - warning: None.
     - parameter
     - returns: None.
     */
    async sendPixelPattern() {
        await this.sendMessage(this.writeString);
        //print("[DotPad_ProcessData] sendPixelPattern : Mobile to Pad : \(dtm_Data_300.bytesToHex(spacing: ""))")
    }

    async sendLine(i) {
        let step = i - 1;
        this.SubString = this.stringwithPadding.substring(step * this.numCell * 2, ((step + 1) * this.numCell * 2));
        this.dotPadSendModule.setDotPadLineCommand(i, "00", "00", this.SubString);
        this.dotPadSendModule.setRefreshMode("GRAPHIC_DISPLAY");
        await this.dotPadSendModule.sendCommand();
    }

    /**
     Send Data to Display Graphic Area through the dotPad_Communication.
     - warning: None.
     - parameter writeString: String
     - returns: None.
     */
    async sendMessage(writeString) { // eslint-disable-line no-unused-vars

        //print("[DotPad_ProcessData] deviceName : \(dotPad_Communication?.deviceName)")
        //print("[DotPad_ProcessData] peripheralIdentifier : \(dotPad_Communication?.peripheral?.name)")

        // console.log("[DotPad_ProcessData] writeString : " + writeString);
        this.stringwithPadding = writeString;

        if (this.stringwithPadding.length != this.length * this.numCell * 2) {
            // console.log("[DotPad_ProcessData] sendMessage : Error : Invalid Count");
            return
        }

        for (let i = 1; i <= this.length; i++) {
            await this.sendLine(i);
        }
    }

    async sendText(textString, index) {
        if (this.dotPad.deviceInfo.deviceName == "DPA768A") {
            return;
        }    

        if (textString.length < 1) {
            // console.log("textString.length : " + textString.length);
            return;
        }

        // console.log("textString.length : " + textString.length);
        let indexMax = Math.ceil(textString.length / 40);

        // console.log("textString.length : " + textString.length, "textString.index : " + index);

        if (index == (indexMax - 1)) {
            this.SubString = textString.substring(index * 20 * 2);
        } else {
            this.SubString = textString.substring(index * 20 * 2, (index + 1) * 20 * 2);
        }

        if (this.dotPad != null && this.dotPad.connected) {
            this.dotPadSendModule.setDotPadLineCommand(0, "80", "00", this.SubString);
            this.dotPadSendModule.setRefreshMode("TEXT_DISPLAY");
            await this.dotPadSendModule.sendCommand();

            return true;
        }
    }

    downAllCell() {
        this.sendMessage("00".repeat(300));
        this.sendText("00".repeat(20), 0);
    }

    /**
     * @param brailleText
     * @param {number} numberOfPins
     * @returns {string}
     */
    changeGraphicDisplayText(brailleText, numberOfPins) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        // 2. 짝수 번째 텍스트 7, 8번 핀을 제외한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(6점식)
        //    짝수 번째 텍스트 7, 8번 핀을 포함한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(8점식)
        const swappedStrings = splitStrings.map((subStr, index) => {
            const number7Pin = numberOfPins === 8 ? subStr.substring(0, 1) : "0";
            const number8Pin = numberOfPins === 8 ? subStr.substring(1, 2) : "0";
            const bitsFromPins1To3 = subStr.substring(2, 5);
            const bitsFromPins4To6 = subStr.substring(5);
            if (index % 2 === 1) {
                return number8Pin + bitsFromPins4To6 + "00000000" + number7Pin + bitsFromPins1To3;
            } else {
                return number7Pin + bitsFromPins1To3 + number8Pin + bitsFromPins4To6;
            }
        });
        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(swappedStrings.join(""));
    }

    /**
     * @param tactileLetters
     * @param {number} numberOfPins
     * @returns {string}
     */
    changeTactileLettersGraphicDisplayText(brailleText) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        const swappedStrings = splitStrings.map((subStr) => {
            return subStr.substring(0, 1) + subStr.substring(2, 5) + subStr.substring(1, 2) + subStr.substring(5);
        });
        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(swappedStrings.join(""));
    }

    hexToBinary(hex) {
        let binary = "";
        for (let i = 0; i < hex.length; i++) {
            const decimal = parseInt(hex[i], 16);
            const fourBits = decimal.toString(2).padStart(4, "0");
            binary += fourBits;
        }
        return binary;
    }

    binaryToHex(binary) {
        let hex = "";
        let i = 0;
        while (i < binary.length) {
            const fourBits = binary.substring(i, i + 4);
            const decimal = parseInt(fourBits, 2);
            hex += decimal.toString(16).toUpperCase();
            i += 4;
        }
        return hex;
    }

    /**
     * @param brailleText
     * @param {number} numberOfPins // 6 dot / 8 dot
     * @param {array} drawPoints
     * @returns {string}
     */
    changeGraphicDisplayMultiline(brailleText, numberOfPins, drawPoints) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        this.lineCount = 0;
        let tempDotCellLineData = this.createTempDotCellLineData(splitStrings, numberOfPins);
        let dotCellLineData = [];
        const drawPointHandlers = {
            '0000': this.handle0000.bind(this),
            '0001': this.handle0001.bind(this),
            '0011': this.handle0011.bind(this),
            '0111': this.handle0111.bind(this),
            '1000': this.handle1000.bind(this),
            '1001': this.handle1001.bind(this),
            '1011': this.handle1011.bind(this),
            '1100': this.handle1100.bind(this),
            '1101': this.handle1101.bind(this),
            '1110': this.handle1110.bind(this),
            '1111': this.handle1111.bind(this),
        }

        drawPoints.forEach((drawPointArr) => {
            let drawPoint = drawPointArr.join().replaceAll(',', '');
            if(drawPointHandlers[drawPoint]) {
                drawPointHandlers[drawPoint](tempDotCellLineData, dotCellLineData, numberOfPins);
            }
        });
        
        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(dotCellLineData.join(""));
    }

    /**
     * Before applying dot cell data blank
     * @param {array} splitStrings
     * @param {number} numberOfPins
     * @returns {array}
     */
    createTempDotCellLineData(splitStrings, numberOfPins) {
        let dotCellLineData = [];
        let rearrangeBits = [];
        splitStrings.forEach((subStr, index) => {
            const tempRearrangeBits = this.rearrangeBits(subStr, numberOfPins, index, rearrangeBits);
            if ((index + 1) % this.LINE_HEIGHT == 0) {
                dotCellLineData.push(tempRearrangeBits);
                rearrangeBits = [];
            }
        });
        return dotCellLineData;
    }
    
    /**
     * rearrange the bit
     * @param {string} subStr
     * @param {number} numberOfPins
     * @param {number} index
     * @param {array} rearrangeBits
     * @returns {rearrangeBits}
     */
    rearrangeBits(subStr, numberOfPins, index, rearrangeBits) {
        // 1. 짝수 번째 텍스트 7, 8번 핀을 제외한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(6점식)
        //    짝수 번째 텍스트 7, 8번 핀을 포함한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(8점식)
        const number7Pin = numberOfPins === this.DOT_CELL_PIN ? subStr.substring(0, 1) : "0";
        const number8Pin = numberOfPins === this.DOT_CELL_PIN ? subStr.substring(1, 2) : "0";
        const bitsFromPins1To3 = subStr.substring(2, 5);
        const bitsFromPins4To6 = subStr.substring(5);
        if(index % 2 === 1) {
            rearrangeBits.push(number8Pin + bitsFromPins4To6 + "0000");
            rearrangeBits.push("0000" + number7Pin + bitsFromPins1To3);
        } else {
            rearrangeBits.push(number7Pin + bitsFromPins1To3 + number8Pin + bitsFromPins4To6);
        }
        
        return rearrangeBits;
    }

    handle0000(dotCellLineData) {
        let tempData = [];
        for(let i = 0; i < this.numCell; i++){
            tempData.push("00000000");
        }
        dotCellLineData.push(tempData);
    }

    handle1111(tempDotCellLineData, dotCellLineData) {
        if(tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data);
            });
        }
        this.lineCount++;
    }
    
    handle0001(tempDotCellLineData, dotCellLineData) {
        if(tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(3, 4) + "000" + data.substring(7, 8) + "000");
            });
        }
    }
    
    handle0011(tempDotCellLineData, dotCellLineData) {
        if(tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(2, 4) + "00" + data.substring(6, 8) + "00");
            });
        }
    }
    
    handle0111(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if(tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(1, 4) + "0" + data.substring(5, 8) + "0");
            });
        }
        if(numberOfPins != this.DOT_CELL_PIN){
            this.lineCount++;
        }
    }
    
    handle1000(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if(numberOfPins != this.DOT_CELL_PIN){
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("000" + data.substring(1, 2) + "000" + data.substring(5, 6));
                });
            }
        } else {
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("000" + data.substring(0, 1) + "000" + data.substring(4, 5));
                });
            }
        }
        this.lineCount++;
    }
    
    handle1001(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if(tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            if(numberOfPins != this.DOT_CELL_PIN){
                tempDotCellLineData[this.lineCount].forEach(data => {
                    tempData1.push(data.substring(1, 2));
                    tempData2.push(data.substring(5, 6));
                });
            } else {
                if(tempDotCellLineData[this.lineCount]) {
                    tempDotCellLineData[this.lineCount].forEach(data => {
                        tempData1.push(data.substring(0, 1));
                        tempData2.push(data.substring(4, 5));
                    });
                }
            }
            if(tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(3, 4) + "00");
                    tempData4.push(data.substring(7, 8) + "00");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }
    
    handle1011(tempDotCellLineData, dotCellLineData) {
        if(tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            tempDotCellLineData[this.lineCount].forEach(data => {
                tempData1.push(data.substring(0, 1));
                tempData2.push(data.substring(4, 5));
            });
            if(tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(2, 4) + "0");
                    tempData4.push(data.substring(6, 8) + "0");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }

    handle1100(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if(numberOfPins != this.DOT_CELL_PIN){
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("00" + data.substring(1, 3) + "00" + data.substring(5, 7));
                });
            }
        } else {
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("00" + data.substring(0, 2) + "00" + data.substring(4, 6));
                });
            }
        }
        this.lineCount++;
    }
    
    handle1101(tempDotCellLineData, dotCellLineData) {
        if(tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            tempDotCellLineData[this.lineCount].forEach(data => {
                tempData1.push(data.substring(0, 2));
                tempData2.push(data.substring(4, 6));
            });
            if(tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(3, 4) + "0");
                    tempData4.push(data.substring(7, 8) + "0");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }
    
    handle1110(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if(numberOfPins != this.DOT_CELL_PIN){
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("0" + data.substring(1, 4) + "0" + data.substring(5, 8));
                });
            }
        } else {
            if(tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("0" + data.substring(0, 3) + "0" + data.substring(4, 7));
                });
            }
        }
        this.lineCount++;
    }
}

    export {
    DotpadSDK
};

